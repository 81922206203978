<template>
  <LiefengContent>
    <template #title>网上家园标签管理</template>
    <template #toolsbarRight>
      <Input
        v-model.trim="labelName"
        :maxlength="20"
        search
        enter-button="查询"
        placeholder="请输入标签名"
        @on-search="search"
        style="width: 250px; margin-right: 5px"
      />
      <Button type="primary" icon="md-add" @click="addTag">新增标签</Button>
      <Button type="warning" icon="ios-stats" @click="analysisFn">统计分析</Button>
    </template>
    <template #contentArea>
      <LiefengTable
        :talbeColumns="talbeColumns"
        :tableData="tableData"
        :loading="loading"
        :fixTable="true"
        :curPage="page"
        :total="total"
        :pagesizeOpts="[20, 30, 50, 100]"
        :page-size="pageSize"
        @hadlePageSize="hadlePageSize"
      >
      </LiefengTable>

      <!-- 新增 -->
      <LiefengModal
        :title="addChangeTitle"
        :value="addChangeStatus"
        width="450px"
        height="120px"
        @input="addChangeStatusFn"
      >
        <template #contentarea>
          <Form
            :rules="addChangeFormValidate"
            :model="addChangeForm"
            :label-width="80"
            ref="addChangeForm"
          >
            <FormItem label="标签名称" prop="labelName">
              <Input
                :maxlength="20"
                v-model.trim="addChangeForm.labelName"
              ></Input>
            </FormItem>
          </Form>
        </template>
        <template #toolsbar>
          <Button
            type="info"
            style="margin-right: 10px"
            @click="addChangeStatus = false"
            >取消</Button
          >
          <Button type="primary" @click="saveAddChange">保存</Button>
        </template>
      </LiefengModal>

      <!-- 关联栏目 -->
      <LiefengModal
        :title="'关联栏目——' + relationTitle"
        :value="relationStatus"
        width="600px"
        height="100vh - 200px"
        @input="relationStatusFn"
      >
        <template #contentarea>
          <div>
            <Button type="primary" style="margin:0 10px" @click="allSelectRelation">全选</Button>
            <Button type="warning" @click="cancelSelectRelation">取消选择</Button>
          </div>
          <CheckboxGroup v-model="relationArr" style="margin: 0 10px">
            <div v-for="item in relationList" :key="item.id">
              <Checkbox
                :label="item.columnCode"
                style="display: block; margin: 10px 0"
                >{{ item.columnName }}</Checkbox
              >
              <template v-if="item.child">
                <div
                  style="margin: 10px 0 10px 2em"
                  v-for="subItem in item.child"
                  :key="subItem.id"
                >
                  <Checkbox
                    style="display: block"
                    :label="subItem.columnCode"
                    >{{ subItem.columnName }}</Checkbox
                  >
                  <template v-if="subItem.child">
                    <Checkbox
                      style="display: block; margin: 10px 0 10px 2em"
                      v-for="k in subItem.child"
                      :key="k.id"
                      :label="k.columnCode"
                      >{{ k.columnName }}</Checkbox
                    >
                  </template>
                </div>
              </template>
            </div>
          </CheckboxGroup>
        </template>
        <template #toolsbar>
          <Button
            type="info"
            style="margin-right: 10px"
            @click="
              relationStatusFn(false);
            "
            >取消</Button
          >
          <Button type="primary" @click="saveRelation">保存</Button>
        </template>
      </LiefengModal>
    </template>
  </LiefengContent>
</template>

<script>
//@route('/tagindex');
import LiefengContent from "@/components/LiefengContent";
import LiefengModal from "@/components/LiefengModal";
import LiefengTable from "@/components/LiefengTable";
export default {
  data() {
    return {
      talbeColumns: [
        {
          title: "标签名称",
          key: "labelName",
          align: "center",
          minWidth: 200,
        },
        {
          title: "使用该标签的总用户数",
          key: "useNum",
          align: "center",
          minWidth: 200,
        },
        {
          title: "带此标签栏目数量",
          key: "columnNum",
          align: "center",
          minWidth: 200,
        },
        {
          title: "操作",
          width: 230,
          align: "center",
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    type: "success",
                    size: "small",
                  },
                  style: {
                    marginRight: "10px",
                  },
                  on: {
                    click: () => {
                      this.relationTitle = params.row.labelName;
                      this.labelCode = params.row.labelCode;
                      this.$Message.loading({
                        content: "正在加载数据，请稍等...",
                        duration: 0,
                      });
                      this.$get('/datamsg/api/pc/platform/label/selectLabelColumn',{
                            labelCode: params.row.labelCode
                        }).then(res => {
                            if(res.code == 200) {
                                this.relationArr = res.dataList;
                            }else {
                                this.$Message.error({
                                    background: true,
                                    content: res.desc
                                })
                            }
                        })
                      this.$get(
                        "/datamsg/api/pc/platform/label/selectColumnTree"
                      )
                        .then((res) => {
                          if (res.code == 200 && res.dataList) {
                            this.relationList = res.dataList;
                            this.$Message.destroy();
                            this.relationStatus = true;
                          } else {
                            this.$Message.destroy();
                            this.$Message.error({
                              background: true,
                              content: res.desc,
                            });
                          }
                        })
                        .catch((err) => {
                          console.log(err);
                          this.$Message.destroy();
                          this.$Message.error({
                            background: true,
                            content: "获取数据失败，请联系管理员处理",
                          });
                        });
                    },
                  },
                },
                "关联栏目"
              ),
              h(
                "Button",
                {
                  props: {
                    type: "primary",
                    size: "small",
                  },
                  style: {
                    marginRight: "10px",
                  },
                  on: {
                    click: () => {
                        this.$Message.loading({
                            content: "正在获取数据，请稍等...",
                            duration: 0
                        })
                      this.$get(
                        "/datamsg/api/pc/platform/label/selectLabelById",
                        {
                          labelId: params.row.id,
                          operatorCustGlobalId:
                            parent.vue.loginInfo.userinfo.custGlobalId,
                          orgCode: parent.vue.loginInfo.userinfo.orgCode,
                          oemCode: parent.vue.oemInfo.oemCode,
                          terminal: "2",
                        }
                      )
                        .then((res) => {
                          if (res.code == 200 && res.data) {
                            this.infoId = params.row.id;
                            this.$refs.addChangeForm.resetFields();
                            this.addChangeForm.labelName = res.data.labelName;
                            this.addChangeTitle = "修改标签";
                            this.$Message.destroy();
                            this.addChangeStatus = true;
                          } else {
                              this.$Message.destroy();
                            this.$Message.error({
                              background: true,
                              content: res.desc,
                            });
                          }
                        })
                        .catch((err) => {
                          console.log(err);
                          this.$Message.destroy();
                          this.$Message.error({
                            background: true,
                            content: "获取数据失败，请联系管理员处理",
                          });
                        });
                    },
                  },
                },
                "修改"
              ),
              h(
                "Button",
                {
                  props: {
                    type: "error",
                    size: "small",
                  },
                  on: {
                    click: () => {
                      this.$Modal.confirm({
                        title: "温馨提示",
                        content: `您正在删除<span style="color: red"> ${params.row.labelName} </span>标签，此操作不可逆，是否继续?`,
                        onOk: () => {
                          this.$post(
                            "/datamsg/api/pc/platform/label/deletePlatformLabel",
                            {
                              labelId: params.row.id,
                              operatorCustGlobalId:
                                parent.vue.loginInfo.userinfo.custGlobalId,
                              orgCode: parent.vue.loginInfo.userinfo.orgCode,
                              oemCode: parent.vue.oemInfo.oemCode,
                              terminal: "2",
                            }
                          )
                            .then((res) => {
                              if (res.code == 200) {
                                this.$Message.success({
                                  background: true,
                                  content: "删除成功",
                                });
                                this.hadlePageSize({
                                  page: this.page,
                                  pageSize: this.pageSize,
                                });
                              } else {
                                this.$Message.error({
                                  background: true,
                                  content: res.desc,
                                });
                              }
                            })
                            .catch((err) => {
                              console.log(err);
                              this.$Message.error({
                                background: true,
                                content: "删除失败，请联系管理员处理",
                              });
                            });
                        },
                      });
                    },
                  },
                },
                "删除"
              ),
            ]);
          },
        },
      ],
      tableData: [],
      loading: false,
      page: 1,
      total: 0,
      pageSize: 20,
      labelName: "",

      //   新增修改
      infoId: "",
      addChangeTitle: "",
      addChangeStatus: false,
      addChangeForm: { labelName: "" },
      addChangeFormValidate: {
        labelName: [
          { required: true, message: "请输入标签名称", trigger: "blur" },
        ],
      },

      //   关联栏目
      relationStatus: false,
      labelCode: '',
      relationList: [],
      relationTitle: "",
      relationArr: [],
    };
  },
  methods: {
    //   统计分析
    analysisFn() {
        this.$Message.info({
            background: true,
            content: "敬请期待"
        })
     },
    //   关联栏目
    saveRelation() {
        if(this.relationArr.length == 0) {
            this.$Message.error({
                background: true,
                content: "请选择要绑定的栏目"
            });
            return;
        }
        this.$Message.loading({
            content: "正在保存数据，请稍等...",
            duration: 0
        })
        this.$post('/datamsg/api/pc/platform/label/relatedColumnCode',{
            labelCode: this.labelCode,
            columnCode: [...new Set(this.relationArr)].join(',')
        }).then(res => {
            if(res.code == 200) {
                this.$Message.destroy();
                this.$Message.success({
                    background: true,
                    content: "保存成功"
                });
                this.relationStatus = false;
                this.hadlePageSize({
                  page: this.page,
                  pageSize: this.pageSize
                })
            }else {
                this.$Message.destroy();
                this.$Message.error({
                    background: true,
                    content: res.desc
                });
            }
        })
    },
    relationStatusFn(status) {
        this.relationStatus = status;
    },
    allSelectRelation() {
        this.relationArr = [];
        this.relationList.map(item => {
            this.relationArr.push(item.columnCode);
            if(item.child) {
                item.child.map(subItem => {
                    this.relationArr.push(subItem.columnCode);
                    if(subItem.child) {
                        subItem.child.map(k => {
                            this.relationArr.push(k.columnCode);
                        })
                    }
                })
            }
        })
    },
    cancelSelectRelation() {
        this.relationArr = [];
    },
    search() {
      this.hadlePageSize({
        page: 1,
        pageSize: this.pageSize,
      });
    },
    saveAddChange() {
      this.$refs.addChangeForm.validate((status) => {
        if (status) {
          let params = {
            labelName: this.addChangeForm.labelName,
            oemCode: parent.vue.oemInfo.oemCode,
            labelType: "0",
          };
          if (this.addChangeTitle == "修改标签") {
            params.id = this.infoId;
          }
          this.$post("/datamsg/api/pc/platform/label/savePlatformLabel", params)
            .then((res) => {
              if (res.code == 200) {
                this.$Message.success({
                  background: true,
                  content: "保存成功",
                });
                this.hadlePageSize({
                  page: this.page,
                  pageSize: this.pageSize,
                });
                this.addChangeStatus = false;
              } else {
                this.$Message.error({
                  background: true,
                  content: res.desc,
                });
              }
            })
            .catch((err) => {
              this.$Message.error({
                background: true,
                content: "保存失败，请联系管理员处理",
              });
            });
        }
      });
    },
    addTag() {
      this.addChangeTitle = "新增标签";
      this.infoId = "";
      this.$refs.addChangeForm.resetFields();
      this.addChangeForm.labelName = "";
      this.addChangeStatus = true;
    },
    addChangeStatusFn(status) {
      this.addChangeStatus = status;
    },
    hadlePageSize(obj) {
      this.loading = true;
      this.$get("/datamsg/api/pc/platform/label/selectPlatformLabelPage", {
        labelName: this.labelName,
        oemCode: parent.vue.oemInfo.oemCode,
        labelType: "0", //标签类型
        page: obj.page,
        pageSize: obj.pageSize,
      })
        .then((res) => {
          if (res.code == 200 && res.dataList) {
            this.tableData = res.dataList;
            this.page = res.currentPage;
            this.pageSize = res.pageSize;
            this.total = res.maxCount;
            this.loading = false;
          } else {
            this.loading = false;
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          this.$Message.error({
            background: true,
            content: "获取数据失败，请联系管理员处理",
          });
        });
    },
  },
  created() {
    this.hadlePageSize({
      page: this.page,
      pageSize: this.pageSize,
    });
  },
  components: {
    LiefengContent,
    LiefengModal,
    LiefengTable,
  },
};
</script>
    
<style scoped lang='less'>
/deep/.ivu-transfer {
  .ivu-transfer-list {
    width: 40%;
    height: calc(100vh - 200px);
  }
}
</style>